export const CRAFTING_PERCENT = 59;

export const MINIMUM_CRAFTING_RECIPES = 1;

export enum QueueTypes {
  COMMON,
  PERSONAL
}

export enum RecipeType {
  FAST = 'fast',
  SUPERFAST = 'super fast'
}

export enum CraftingQueueStatus {
  REACHED = 'reached',
  REMAINING = 'remaining'
}

export enum CraftingQueueStatusMessage {
  COMMON_QUEUE_OVERLOAD = 'common_queue_overload',
  PERSONAL_QUEUE_OVERLOAD = 'personal_queue_overload'
}
