export function calculateTimeLeftPercent(startTime: number, endTime: number, currentTime: number): number {
  const totalTime = endTime - startTime;
  const timePassed = currentTime - startTime;

  if (totalTime <= 0 || timePassed <= 0) {
    return 0;
  }

  return (timePassed / totalTime) * 100;
}

export function encodeIndexes(indexes: number[][]): string[] {
  const chunkedArray = [];

  for (let i = 0; i < indexes.length; i++) {
    const converted =
      `0x${indexes[i].length.toString(16).padStart(4, '0')}` +
      indexes[i]
        .map((index) => `${index.toString(16).padStart(6, '0')}`)
        .join('')
        .padStart(60, '0');
    chunkedArray.push(converted);
  }

  return chunkedArray;
}
